import React from 'react'
import styled from 'styled-components'

import ContentDefault from '../components/container'
import Layout from '../components/layout'
import banner from '../images/banner.png'

const Banner = styled.div`
  width: 100%;
  height: 160px;
  background-image: url(${banner});
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
`

const Content = styled(ContentDefault)`
  padding: 50px 0;
`

const Title = styled.h1`
  color: #102549;
  font-size: 32px;
  font-weight: 600;
  text-align: center;
  margin-bottom: 32px;
`

const Subtitle = styled.h2`
  font-size: 26px;
  font-weight: 600;
`

const Text = styled.p``

const SobreNos = () => (
  <Layout title='Sobre Nós' page='sobre-nos'>
    <Banner />
    <Content>
      <Title>História do Aeroclube do Rio Grande do Norte</Title>

      <Subtitle>Fundação</Subtitle>
      <Text>
        Clube fundado em 11 de fevereiro de 1928, e sua sede própria localizada na futura
        Av. Hermes da Fonseca é inaugurada no dia 29 de dezembro de 1928. Este evento
        contou com membros da sociedade potiguar e diversos convidados, representantes dos
        governos estaduais e ministro da viação. O ponto auge das festividades, aconteceu
        após o discurso memorável do exmo. Presidente do Estado e primeiro presidente do
        Aeroclube, Juvenal Lamartine de Faria, quando todos os presentes, sócios
        fundadores e autoridades representadas, assinaram a ATA de fundação do Aeroclube.
        Esta área outrora pertencera ao ex governador Alberto Maranhão que cedera por
        quantia simbólica esta área a pedido do Exmo. Governador Juvenal Lamartine.
      </Text>

      <Subtitle>Primeira Diretoria</Subtitle>
      <Text>
        Presidente- Juvenal Lamartine(governador do Estado), Fernando Pedrosa (1º
        vice-presidente), Décio Fonseca (2º vice-presidente), Omar O’Grady (3º
        vice-presidente), Adauto da Câmara (1º secretário), Aníval Calmon Costa (2º
        secretário), Francisco Ivo Filho (procurador), Renato Dantas (orador), Ulisses
        Medeiros (tesoureiro), Alfredo Barbalho (bibliotecário) e o comandante Djalma
        Petit (diretor técnico e instrutor de vôo) gentilmente cedido pela Marinha de
        Guerra do Brasil, além da comissão de tomada de contas, com Cristóvan Dantas, Eric
        Gordon e Carlos Filgueira. Destacamos o grande apoio recebido pelo Natalense e
        piloto da Marinha Manoel Vasconcelos, um dos grandes nomes da aviação militar
        nacional. Foi através da sua influência que DJALMA PETIT veio cedido para Natal.
      </Text>

      <Subtitle>Golpe de 1930</Subtitle>
      <Text>
        A atividade do Clube esteve “adormecida” após o golpe militar de 1930, quando
        Juvenal Lamartine foi exilado na Europa e com a ida para o Rio de Janeiro de
        Fernando Pedroza, para cuidar dos seus negócios. Em 1933 assume Januário Cicco. Em
        1936 o Presidente é José Fernandes de Vasconcelos. Entre 1937/1938 assume José
        Fernandes de Queiroz. Sucedido por Gentil Ferreira de Souza.Em 1941, Fabrício
        Gomes Pedroza, assume a presidência do Clube, recuperando o novo ciclo de
        Desenvolvimento do Aero. Seguem Fernando Gomes Pedroza(filho), Olavo João Galvão,
        José Cavalcante Melo,Sérgio Severo(1951). Neste período o então Governador Sílvio
        Pedroza promulgou a lei N. 582 de 06 de dezembro de 1951 oficializando a doação do
        prédio e do terreno situado na Av. Hermes da Fonseca. Outros Presidentes: Floro
        Dória,Wilson Miranda, Wellignton Xavier, Comandante Graco Magalhães, Coronel Myron
        Campelo, Comandante Graco Magalhães(novamente), Mário Dal Santos, Flávio Mousinho,
        Marcondes Pinheiro, Moab Rodrigues, Luiz Alberto Rocha e atualmente o Médico e
        Professor da UFRN, Fábio Macêdo.
      </Text>

      <Subtitle>O Surgimento da Escola de Pilotos</Subtitle>
      <Text>
        Sendo a aviação uma área nobre o visionário Governador JUVENAL LAMARTINE DE FARIA,
        proporcionou a criação de uma das mais tradicionais Escolas de aviação Brasileira.
        Na época, este tradiconal Clube foi filiado ao Aeroclube do Brasil.
      </Text>
      <Text>
        O clube já tinha sede própria, pista de pouso de aviões e Hangar, foi aí que
        surgiu a necessidade de um piloto e instrutor. Destacamos o grande apoio recebido
        pelo Natalense e piloto da Marinha Manoel Vasconcelos, um dos grandes nomes da
        aviação militar nacional.
      </Text>
      <Text>
        Através da influência de Vasconcelos, foi convidado para a função de instrutor, o
        Capitão da Marinha Djalma Petit, que teria a dupla missão: servir ao Governo do
        Estado, e ministrar aulas na Escola de Vôo do Aeroclube.
      </Text>
      <Text>
        Petit chegou a Natal no dia 31 de março de 1928 já no posto de Capitão-Tenente
        aviador da Marinha brasileira, tendo sido o primeiro instrutor do Aeroclube do
        R.G. do Norte. Chegou e foi logo assumindo o cargo.
      </Text>
      <Text>
        Durante a sua permanência nesta capital dinamizou bastante as atividades aéreas.
        Iniciou os preparativos necessários para o início das aulas, que só foi possível
        no ano seguinte.
      </Text>
      <Text>
        Os aviões ( duas unidades, chamados “Blue-bird” da fabricante Blackburn) já haviam
        chegado pelo porto de Recife e Djalma Petit providenciou a vinda deles voando para
        Natal. A princípio, ficaram estacionados no campo de Parnamirim, Base da Air
        France, local que seria a base de Parnamirim e o futuro Aeroporto Internacional
        Augusto Severo. Fernando Pedroza, empresário e grande amante da aviação tem um
        grande destaque, pois ele e sua esposa, foram à Inglaterra e vsitaram a referida
        fábrica, finalizando a compra destes aviões.
      </Text>
      <Text>
        Petit foi o primeiro piloto a pousar na pista do Aeroclube com um dos aviões do
        Clube. Preparou uma turma de novos pilotos que em fevereiro de 1930 já concluíra o
        curso.
      </Text>
      <Text>Fizeram parte desta turma os seguintes alunos:</Text>
      <Text>
        Fernando Pedroza, Eloi Caldas, Aldo Cariello, Plínio Saraiva, Octávio Lamartine e
        Edgar Dantas. Este último recém-formado aviador faleceu num acidente aéreo nas
        comemorações do encerramento do curso, sendo considerado a primeira vítima civil
        da aviação brasileira.
      </Text>
      <Text>
        Natal sempre foi uma “base” da aviação mundial. Na década de 20, inúmeros
        aviadores desbravavam o cruzamento do atlântico e tenham em nossa cidade, passagem
        obrigatória. Passagem marcante da aviação da época, foi o vôo recorde dos
        italianos Ferrarin e Del Petre no Savoia-Marchetti S-64. Alça vôo no dia 3 de
        julho de 1928, ás 18:51 h. de Roma, no horário local e sobrevoaram Natal às 16:10
        h do dia 05 de julho de 1928, mas pela precárias condições de tempo, procuraram o
        litoral em direção a TOUROS, onde fizeram um pouso forçado. Voaram por 9.540 km,
        por cerca de 49 horas.
      </Text>
      <Text>
        Na estadia em Natal, os heróis italianos foram recebidos pelo então Governador e
        Presidente do Aeroclube do RN, Juvenal Lamartine, onde foram agraciados com o
        título de cidadãos natalenses. Primeiros pilotos estrangeiros a receberem este
        título. Após este feito, O presidente Italiano Mussolini doou a Natal uma coluna
        captolina com mais de 2000 anos, que se encontra no Instituto Histórico de Natal.
      </Text>
      <Text>
        Outro vôo histórico fez o francês Jean Mermoz que amerrissava no Rio Potengi no
        dia 13 de maio de 1930, a bordo do “Laté 28″, após voar por cerca de 3.100 km de
        São Luiz do Senegal (África) a Natal. Voou por 20 horas sem escala e foi criada
        uma comissão do Aeroclube do RN, nomeada pela Diretoria que concluiu: Mermoz bateu
        o recorde mundial de vôo em linha reta, em um hidroavião.
      </Text>
      <Text>
        A atividade do Clube esteve “adormecida” após o golpe militar de 1930, quando
        Juvenal Lamartine foi exilado na Europa e com a ida para o Rio de Janeiro de
        Fernando Pedroza. Em 1941, Fabrício Gomes Pedroza, assume a presidência do Clube,
        recuperando o novo ciclo de Desenvolvimento do Aero. Após Juvenal Lamartine
        assumem vários presidentes: Fernando Pedroza, Fabrício Gomes Pedroza, Fernando
        Gomes Pedroza(filho), Gentil Ferreira, Sérgio Severo, Wellignton Xavier,
        Comandante Graco Magalhães, Coronel Campelo, Graco Magalhães, Dal Santos, Flávio
        Mousinho, Marcondes Pinheiro, Moab Rodrigues, Luiz Alberto Rocha.
      </Text>
    </Content>
  </Layout>
)

export default SobreNos
